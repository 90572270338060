import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import NumberField from '@pretto/bricks/components/form/NumberField'
import SvgAnimation from '@pretto/bricks/components/images/SvgAnimation'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import Text from '@pretto/bricks/components/typography/Text'
import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import { formatInputNumber, formatNumber } from '@pretto/bricks/core/utility/formatters'
import Row from '@pretto/bricks/shared/components/Row'

import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'

import * as S from './styles'

const CONFIG = {
  payment: {
    animation: 'house',
    label: 'Votre mensualité',
    suffix: '€ / mois',
    title: 'Votre mensualité sera de :',
  },
  principal: {
    animation: 'house',
    label: 'Votre prêt',
    suffix: '€',
    title: 'Votre prêt sera de :',
  },
}

const CalculatorResult = ({ children, onChange, onCtaClick, onKeyDown, step, title, type, value, yPosition }) => {
  const stickyRef = useRef(null)
  const [isStickyOpen, setIsStickyOpen] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const { current } = stickyRef
    if (current) {
      if (stickyRef.current.getBoundingClientRect().y < 0) setIsStickyOpen(true)
      else setIsStickyOpen(false)
    }
  }, [yPosition])
  const handleScrollTop = () => {
    if (window.scrollTo)
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    else document.querySelector('body').scrollIntoView()
  }
  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur = () => {
    setIsFocused(false)
  }
  const { animation, label, suffix, title: subTitle } = CONFIG[type]

  return (
    <S.Background>
      <S.Wrapper full="laptop">
        <S.Result>
          <Transition in={isStickyOpen} timeout={500} unmountOnExit>
            {state => (
              <S.Sticky isEntered={state === 'entered'} onClick={handleScrollTop}>
                <Wrapper>
                  <Row label={label} value={<Heading>{formatNumber(value, { suffix })}</Heading>} />
                </Wrapper>
              </S.Sticky>
            )}
          </Transition>
          <S.CloudLeft />
          <S.CloudRight />
          <ResponsiveSSR min="laptop">
            <S.Heading>
              <Heading type="h1">{title}</Heading>
            </S.Heading>
          </ResponsiveSSR>
          <S.Head>
            <div>
              <ResponsiveSSR max="laptop">
                <S.Title>{subTitle}</S.Title>
              </ResponsiveSSR>
              <S.Value ref={stickyRef}>
                {isFocused ? (
                  <NumberField
                    autoFocus
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    format="display"
                    variant="accent-1"
                    suffix={suffix}
                    onBlur={handleBlur}
                  />
                ) : (
                  <S.DisplayValueContainer>
                    <S.DisplayValue onClick={handleFocus}>
                      <S.DisplayValueNumber>{formatInputNumber(value)}</S.DisplayValueNumber>
                      <S.DisplayValueSuffix>{suffix}</S.DisplayValueSuffix>
                    </S.DisplayValue>
                  </S.DisplayValueContainer>
                )}
              </S.Value>
              <ResponsiveSSR min="laptop">
                <S.Description>
                  <Text size="small">
                    Pour un calcul plus précis, réalisez gratuitement et sans inscription la simulation de votre prêt.
                  </Text>
                </S.Description>

                <S.Button>
                  <ButtonLegacy
                    variant="accent-1"
                    href="https://app.staging.pretto.fr"
                    onClick={onCtaClick}
                    icon="arrow-right"
                    iconPosition="right"
                    size="large"
                  >
                    Simulation personnalisée
                  </ButtonLegacy>
                </S.Button>
              </ResponsiveSSR>
            </div>
          </S.Head>
          <S.AnimationWrapper>
            <S.Animation>
              <SvgAnimation type={animation} step={step} />
            </S.Animation>
          </S.AnimationWrapper>
        </S.Result>
        {children && <S.Aside>{children}</S.Aside>}
      </S.Wrapper>
    </S.Background>
  )
}

CalculatorResult.propTypes = {
  /** Aside content of component. */
  children: PropTypes.node,
  /** Used when amount is changed by user. */
  onChange: PropTypes.func.isRequired,
  /** Additional action when CTA is clicked. */
  onCtaClick: PropTypes.func,
  /** Used when amount is changed by user with arrows. */
  onKeyDown: PropTypes.func,
  /** Current step of animation. See [`SvgAnimation`](/#!/SvgAnimation) component. */
  step: PropTypes.number.isRequired,
  /** Title of page. Only used for desktop. */
  title: PropTypes.string.isRequired,
  /** Type of Result. */
  type: PropTypes.oneOf(Object.keys(CONFIG)).isRequired,
  /** Value to be displayed. */
  value: PropTypes.number.isRequired,
  /** Scroll Y position. */
  yPosition: PropTypes.number,
}

export default CalculatorResult
