import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'
import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import CalculatorDetails from '@pretto/bricks/website/calculator/components/CalculatorDetails'
import CalculatorResult from '@pretto/bricks/website/calculator/components/CalculatorResult'
import CalculatorSlider from '@pretto/bricks/website/calculator/components/CalculatorSlider'
import SimulatorLinkCardList from '@pretto/bricks/website/simulators/components/SimulatorLinkCardList'

import { SwitchToggle } from '@pretto/zen/atoms/selectors/SwitchToggle/SwitchToggle'

import PropTypes from 'prop-types'

import * as S from './styles'

const CalculatorPage = ({
  detailsProps,
  durationSliderProps,
  insuranceRateSliderProps,
  isMoreOptionsActive,
  onCtaClick,
  onToggleMoreOptions,
  paymentSliderProps,
  principalSliderProps,
  rateSliderProps,
  resultProps,
  simulators,
  type,
}) => {
  const rateSliders = (
    <>
      <S.Slider>
        <CalculatorSlider type="rate" {...rateSliderProps} />
      </S.Slider>
      <S.Slider>
        <CalculatorSlider type="insuranceRate" {...insuranceRateSliderProps} />
      </S.Slider>
    </>
  )

  const content = (
    <Content>
      <S.Slider>
        {type === 'payment' && <CalculatorSlider type="principal" {...principalSliderProps} />}
        {type === 'principal' && <CalculatorSlider type="payment" {...paymentSliderProps} />}
      </S.Slider>
      <S.Slider>
        <CalculatorSlider type="duration" {...durationSliderProps} />
      </S.Slider>
      <ResponsiveSSR max="laptop">
        <S.Options>
          <S.OptionsLabel>Plus d'options</S.OptionsLabel>
          <SwitchToggle isToggled={isMoreOptionsActive} onToggle={onToggleMoreOptions} />
        </S.Options>
        <CollapsibleRow in={isMoreOptionsActive}>{rateSliders}</CollapsibleRow>
      </ResponsiveSSR>
      <ResponsiveSSR min="laptop">{rateSliders}</ResponsiveSSR>
    </Content>
  )

  return (
    <>
      <S.Hero>
        <S.Result>
          <ResponsiveSSR max="laptop">
            <CalculatorResult {...resultProps} onClick={onCtaClick} />
          </ResponsiveSSR>
          <ResponsiveSSR min="laptop">
            <CalculatorResult {...resultProps} onClick={onCtaClick}>
              <ResponsiveSSR min="laptop">
                <Card variant="white" zDepth={2}>
                  {content}
                </Card>
              </ResponsiveSSR>
            </CalculatorResult>
          </ResponsiveSSR>
        </S.Result>
      </S.Hero>

      <S.Page full="desktop">
        <ResponsiveSSR max="laptop">
          {content}
          <S.Button>
            <ButtonLegacy
              href="https://app.staging.pretto.fr"
              onClick={onCtaClick}
              size="large"
              icon="arrow-right"
              iconPosition="right"
              variant="accent-1"
            >
              Simulation complète
            </ButtonLegacy>
          </S.Button>
        </ResponsiveSSR>

        <S.Details>
          <CalculatorDetails {...detailsProps} />
        </S.Details>
      </S.Page>

      <S.Simulators>
        <Wrapper>
          <S.SimulatorsTitle>
            <SubHeading size="large">Nos autres calculettes</SubHeading>
          </S.SimulatorsTitle>
          <SimulatorLinkCardList elements={simulators} />
        </Wrapper>
      </S.Simulators>
    </>
  )
}

CalculatorPage.propTypes = {
  /** Props of [`CalculatorDetails`](/#!/CalculatorDetails) component. */
  detailsProps: PropTypes.object.isRequired,
  /** Props of [`CalculatorSlider`](/#!/CalculatorSlider) component with type _duration_. */
  durationSliderProps: PropTypes.object.isRequired,
  /** Props of [`CalculatorSlider`](/#!/CalculatorSlider) component with type _insuranceRate_. */
  insuranceRateSliderProps: PropTypes.object.isRequired,
  /** Show / hide options below toggle button. */
  isMoreOptionsActive: PropTypes.bool.isRequired,
  /** Additional action when CTA is clicked. */
  onCtaClick: PropTypes.func,
  /** Toggle for `isMoreOptionsActive` prop. Only used on mobile. */
  onToggleMoreOptions: PropTypes.func,
  /** Props of [`CalculatorDetails`](/#!/CalculatorDetails) component with type _payment_. */
  paymentSliderProps: PropTypes.object.isRequired,
  /** Props of [`CalculatorDetails`](/#!/CalculatorDetails) component with type _principal_. */
  principalSliderProps: PropTypes.object.isRequired,
  /** Props of [`CalculatorDetails`](/#!/CalculatorDetails) component with type _rate_. */
  rateSliderProps: PropTypes.object.isRequired,
  /** Props of [`CalculatorResult`](/#!/CalculatorResult) component. */
  resultProps: PropTypes.object.isRequired,
  /** `elements` of [`SimulatorLinkCardList`](/#!/SimulatorLinkCardList) component. */
  simulators: PropTypes.array.isRequired,
  /** Type of primary result shown. */
  type: PropTypes.oneOf(['payment', 'principal']),
}

export default CalculatorPage
